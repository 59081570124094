
import Vue from 'vue';
import Component from 'vue-class-component';
import { Emit, Prop } from 'vue-property-decorator';
import { Inject } from '@/core/di/Inject';
import { TYPES } from '@/core/config/Types';
import { TransportFindAll } from '@/tracking/application/uses_cases/typetransport/search/findall/TransportFindAll';
import { CreateTimeline } from '@/tracking/application/uses_cases/timeline/create/CreateTimeline';
import { EventsFindByProcessType } from '@/tracking/application/uses_cases/events/search/EventsFindByProcessType';
import { UpdateTimeline } from '@/tracking/application/uses_cases/timeline/update/UpdateTimeline';
import { OperationFindAll } from '@/tracking/application/uses_cases/typeoperation/search/OperationFindAll';
import i18n from '@/core/plugins/i18n';

@Component({
  name: 'CreateTimelineFF'
})
export default class CreateTimelineFF extends Vue {
  @Inject(TYPES.FINDALL_TRANSPORT)
  readonly transportFindAll!: TransportFindAll;
  @Inject(TYPES.CREATE_TIMELINE)
  readonly createTimeline!: CreateTimeline;
  @Inject(TYPES.UPDATE_TIMELINE)
  readonly updateTimeline!: UpdateTimeline;
  @Inject(TYPES.FINDEVENTSBYPROCESSTYPE_EVENTS)
  readonly eventsFindByProcessType!: EventsFindByProcessType;
  @Inject(TYPES.FINDALL_TYPE_OPERATION)
  readonly operationFindByProcessType!: OperationFindAll;
  //Data
  operationalProfilesStore = JSON.parse(localStorage.getItem('operationalProfiles') as any);
  operationalProfilesSelected = 'a';
  target = i18n.t('general.timeline');
  typeTransportList = [];
  processTypeList = [];
  typeOperationList = [];
  processTypeTable = [];
  isLoading = false;
  fullPage = true;
  private formCreateTimeline: any = {
    timelinedDescription: '',
    isTouched: false,
    dataTypeTransport: [],
    dataProcessType: [],
    dataTypeOperation: [],
    configFor: [],
    edit: null
  };
  //Prop
  @Prop() readonly timelineList!: any;
  @Prop() readonly validationSave!: boolean;
  @Prop() readonly isEdit!: boolean;
  @Prop() readonly verifyTimelineCreationCourier!: Function;
  //Refs
  $refs!: {
    timelinedDescription: HTMLInputElement;
  };
  //Hook
  mounted() {
    this.$nextTick(() => this.getTimeline(this.timelineList));
    this.$nextTick(() => this.typeTransport);
    this.$nextTick(() => this.eventsProcessType());
    this.$nextTick(() => this.typeOperation);
    if (!this.isEdit) {
      this.$nextTick(() => this.clearForm());
    }
  }
  //Computed
  get rows() {
    return this.processTypeTable.length;
  }
  eventsProcessType() {
    this.eventsFindByProcessType.execute(3).then((response: any) => {
      this.processTypeList = [];
      this.processTypeList = response;
      if (this.formCreateTimeline.configFor != undefined) {
        this.processTypeList = this.processTypeList.filter((item: any) => {
          return this.formCreateTimeline.configFor == 'u' ? item.forCourier : !item.forCourier;
        });
      }
    });
    return true;
  }
  public get typeTransport() {
    this.transportFindAll.execute().then((response: any) => {
      this.typeTransportList = response;
    });
    return true;
  }
  public get typeOperation() {
    this.operationFindByProcessType.execute().then((response: any) => {
      this.typeOperationList = response;
    });
    return true;
  }

  get operationalProfiles() {
    const arrayProfile = [
      {
        text: 'wms',
        value: 'w',
        notEnabled: false
      },
      {
        text: 'courier',
        value: 'u',
        notEnabled: false
      },
      {
        text: 'tracking',
        value: 'c',
        notEnabled: false
      }
    ] as any;

    arrayProfile.map((item: any) => {
      if (!this.operationalProfilesStore.find((element: any) => element == item.text)) {
        item.notEnabled = true;
      }
      item.text =
        item.text == 'wms'
          ? this.$t('menu.WMS')
          : item.text == 'courier'
          ? this.$t('menu.Courier')
          : item.text == 'tracking'
          ? this.$t('menu.Freight')
          : '';
    });
    return arrayProfile;
  }
  //method
  getValidationState({ dirty, validated, valid = null }: { dirty: any; validated: any; valid: any }) {
    return dirty || validated ? valid : null;
  }
  public closeModal() {
    this.$emit('closeModal');
  }
  public getTimeline(data: any) {
    if (this.isEdit) this.processTypeTable = [];
    this.formCreateTimeline.edit = data.edit;
    this.formCreateTimeline.timelinedDescription = data.description;
    this.formCreateTimeline.dataTypeTransport = {
      id: data.typeTransport,
      name: data.typeTransportName
    };
    this.formCreateTimeline.dataTypeOperation = {
      code: data.typeOperation,
      description: data.typeOperationName
    };
    this.processTypeTable = data.events;
    this.formCreateTimeline.configFor = data.configFor;
  }

  public editEventTimeline() {
    if (this.isEdit) {
      const payloadUpdate: any = {
        id: this.timelineList.id,
        description: this.formCreateTimeline.timelinedDescription.trim(),
        typeTransport: this.formCreateTimeline.dataTypeTransport.id,
        typeOperation: this.formCreateTimeline.dataTypeOperation.code,
        processType: 3,
        active: true,
        events: this.processTypeTable,
        configFor: this.formCreateTimeline.configFor
      };
      this.isLoading = true;
      this.updateTimeline
        .execute(payloadUpdate)
        .then((response: any) => {
          this.isLoading = false;
          this.getTimelineSkeleton();
          return response;
        })
        .catch((error: any) => {
          this.isLoading = false;
          throw new Error(error);
        });
    }
  }

  public saveTimeline() {
    if (this.verifyTimelineCreationCourier(this.formCreateTimeline) && this.formCreateTimeline.configFor === 'u') {
      this.$swal(
        `${this.$t('general.cannotCreateTimeline')}`,
        `${this.$t('general.cannotCreateTimelineDescription')}`,
        'warning'
      );
      return;
    }

    for (const index in this.formCreateTimeline.dataProcessType) {
      this.formCreateTimeline.dataProcessType[index].sequence = parseInt(index) + 1;
    }
    const eventList: any = [];
    this.formCreateTimeline.dataProcessType.forEach((event: any) => {
      event.eventReference = event.id;
      for (const item of event.activities) {
        item.eventReference = event.eventReference;
      }
      eventList.push(event);
    });

    const payloadSave: any = {
      id: null,
      description: this.formCreateTimeline.timelinedDescription.trim(),
      typeTransport: this.formCreateTimeline.dataTypeTransport.id,
      typeOperation: this.formCreateTimeline.dataTypeOperation.code,
      processType: 3,
      active: true,
      events: eventList,
      routeId: 0,
      configFor: this.formCreateTimeline.configFor
    };

    if (this.processTypeTable.length > 0) {
      this.isLoading = true;
      this.updateTimeline
        .execute(payloadSave)
        .then((response: any) => {
          this.isLoading = false;
          this.getTimelineSkeleton();
          return response;
        })
        .catch((error: any) => {
          this.isLoading = false;
          throw new Error(error);
        });
    } else {
      this.isLoading = true;
      this.createTimeline
        .execute(payloadSave)
        .then((response: any) => {
          this.isLoading = false;
          this.getTimelineSkeleton();
          return response;
        })
        .catch((error: any) => {
          this.isLoading = false;
          throw new Error(error);
        });
    }
  }
  public clearForm() {
    this.typeTransportList = [];
    this.processTypeList = [];
    this.processTypeTable = [];
    this.formCreateTimeline = {
      timelinedDescription: '',
      isTouched: false,
      dataTypeTransport: [],
      dataProcessType: [],
      edit: null
    };
    this.$nextTick(() => this.$refs.timelinedDescription.focus());
  }
  //Emit
  @Emit()
  public getTimelineSkeleton() {
    this.$emit('getTimelineSkeleton');
  }
}
